<template>
  <div>
    <v-tabs v-model="strTab" centered icons-and-text>
      <v-tab href="#tab-cadastro">
        Cadastro Geral
        <i :class="$utilidade.getIcone('cadastroGeral') + ' fa-2x'"></i>
      </v-tab>

      <v-tab href="#tab-estatisca" v-if="intTipoServicoId != 1410" :disabled="formData.intId == 0">
        Estatísticas
        <i :class="$utilidade.getIcone('estatistica') + ' fa-2x'"></i>
      </v-tab>

      <v-tab href="#tab-email" :disabled="formData.intId == 0">
        Histórico de Envio
        <i :class="$utilidade.getIcone('historico') + ' fa-2x'"></i>
      </v-tab>
    </v-tabs>

    <ed-form
      :formData="formData"
      :handlerSave="strTab != 'tab-cadastro' ? null : salvar"
    >
      <cadastro-geral
        :formData="formData"
        v-show="strTab == 'tab-cadastro'"
        :key="'cadastro-' + $root.$session.versao"
      />

      <ed-log-email
        v-if="strTab == 'tab-estatisca' && formData.intId>0"
        strPointType="Campanha"
        :intPointId="formData.intId"
      />

      <ed-log-email
        v-if="strTab == 'tab-email' && formData.intId>0"
        strPointType="Campanha"
        :intPointId="formData.intId"
        boolDisableEstatistica
      />
      <!-- <conteudo
        :formData="formData"
        v-show="strTab == 'tab-conteudo'"
        :key="'conteudo-' + $root.$session.versao"
      />

      <codigo
        :formData="formData"
        v-show="strTab == 'tab-codigo'"
        :key="'codigo-' + $root.$session.versao"
      />

      <imagem
        :formData="formData"
        v-show="strTab == 'tab-imagem'"
        :key="'imagem-' + $root.$session.versao"
      /> -->
    </ed-form>
  </div>
</template>

<script>
import { EdForm } from "@/components/common/form";
import CadastroGeral from "./partials/geral.vue";
import EdLogEmail from "@/components/common/log/email/index";

export default {
  name: "EdModelo",
  props: {
    intTipoServicoId: {
      type: [Number, String],
      required: true,
      default: null,
    },
    intId: {
      type: [Number, String],
      required: false,
      default: null,
    },
  },
  components: {
    EdForm,
    CadastroGeral,
    EdLogEmail,
  },
  provide() {
    return {
      bind: this.bind,
    };
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      bind: {
        objTipoModelo: null,
        arrayParametros: [],
      },
      strTab: "tab-cadastro",
      formData: {
        intId: 0,
        intTipoServicoId: this.intTipoServicoId,
        intTipoCampanhaId: this.intTipoServicoId == 1410 ? 1413 : null,
        intTipoStatusId: null,
        intTipoEnvioId: null,
        boolAtivo: 1,
        strDataInicio: this.$utilidade.toDate(null, true),
        strValor: {
          arrayEmpresa: [],
        },
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    initialize() {
      this.$root.$dialog.loading(true);

      Promise.all([
        this.intId
          ? this.$root.$request.get("Campanha/Campanha", {
              intId: this.intId,
            })
          : null,
      ]).then(([objCampanha]) => {
        this.$root.$dialog.loading(false);
        this.formatarDados(objCampanha);
      });
    },

    formatarDados(objResponse) {
      if (objResponse && objResponse.status == 200) {
        this.formData = Object.assign(objResponse.result, {});
      }
    },

    salvar() {
      this.$root.$request
        .post("Campanha/Campanha", this.formData)
        .then((objResponse) => {
          if (objResponse.status == 200) {
            if (this.$route.name.indexOf(".edit") == -1) {
              this.$router.push({
                name: this.$route.name.replace(".register", "") + ".edit",
                params: { intId: objResponse.result.intId },
              });
            } else {
              this.formatarDados(objResponse);
            }
          }
        });
    },
  },
};
</script>
