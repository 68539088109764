<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
        <span class="form-title">Cadastro Geral</span>

        <div class="row">
          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="formData.strNome"
            name="strNome"
            label="Nome"
            rules="required"
            :disabled="!formData.boolAtivo ? true : false"
          />

          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-0"
            v-model="formData.strAssunto"
            name="strAssunto"
            label="Assunto"
            rules="required"
            :disabled="!formData.boolAtivo ? true : false"
          />

          <ed-input-auto-complete
            v-if="formData.intTipoServicoId != 1410"
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-0 pt-0"
            v-model="formData.intListaId"
            label="Lista de Contato"
            name="intListaId"
            route="Campanha/Lista"
            :filters="{
              strBusca: this.formData.intListaId,
            }"
            rules="required"
            :disabled="!formData.boolAtivo || formData.intId ? true : false"
            :itemList="formData.lista ? [formData.lista] : []"
          />

          <ed-input-auto-complete
            v-if="formData.intTipoServicoId != 1410"
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-0 pt-0"
            v-model="formData.intModeloId"
            label="Modelo de Campanha"
            name="intModeloId"
            route="Documento/Modelo"
            :filters="{
              strBusca: this.formData.intModeloId,
              intTipoDocumentoId: 1427,
            }"
            rules="required"
            :disabled="!formData.boolAtivo || formData.intId ? true : false"
            :itemList="formData.modelo ? [formData.modelo] : []"
          />
          <ed-input-tipo
            v-if="formData.intTipoServicoId != 1410"
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-0"
            v-model="formData.intTipoCampanhaId"
            name="intTipoCampanhaId"
            label="Tipo de Campanha"
            rules="required"
            tipo="1412"
            :disabled="!formData.boolAtivo || formData.intId ? true : false"
          />

          <ed-input-tipo
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-0"
            v-model="formData.intTipoEnvioId"
            name="intTipoEnvioId"
            label="Tipo de Envio"
            rules="required"
            tipo="1415"
            :disabled="!formData.boolAtivo ? true : false"
          />

          <ed-input-date
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pt-0"
            v-model="formData.strDataInicio"
            name="strDataInicio"
            label="Data de Inicialização"
            rules="required"
            info="Essa data define o início dos envios"
            :disabled="!formData.boolAtivo ? true : false"
          />

          <ed-input-date
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pt-0"
            v-model="formData.strDataFim"
            name="strDataFim"
            label="Data de Finalização"
            info="Essa data define o fim dos envios. Deixe em branco para desativar a finalização"
            :disabled="!formData.boolAtivo ? true : false"
            clearable
          />

          <ed-input-switch
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="formData.boolAtivo"
            name="boolAtivo"
            label="Campanha ativa?"
          />
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
        <span class="form-title"  v-if="formData.intTipoServicoId == 1410">Vincular Empresa</span>
        <div class="row" v-if="formData.intTipoServicoId == 1410">
          <ed-input-auto-complete
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-0 pt-0"
            v-model="formData.strValor.arrayEmpresa"
            label="Enviar para as Empresas"
            name="arrayEmpresaEmail"
            item-text="strNome"
            route="Cadastro/CadastroGeral"
            placeholder="Todas"
            :filters="{ intTipoCadastroGeralId: 58,strBusca:(formData.strValor.arrayEmpresa.length ? formData.strValor.arrayEmpresa : null) }"
            :initializeItems="formData.strValor.arrayEmpresa.length  ? true : false"
            clearable
            multiple
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  EdInputText,
  EdInputDate,
  EdInputTipo,
  EdInputSwitch,
  EdInputAutoComplete,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: false,
      default: null,
    },
  },
  components: {
    EdInputText,
    EdInputDate,
    EdInputTipo,
    EdInputSwitch,
    EdInputAutoComplete,
  },
  inject: ["bind"],
  mounted() {},
  created() {},
  data() {
    return {};
  },
  watch: {},
  methods: {},
};
</script>
